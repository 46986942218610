/**
 * Cargando
 * @returns {string}
 */
function cargando() {
    return '<div align="center"><img src="http://loadinggif.com/generated-image?imageId=11&bgColor=%23ffffff&fgColor=%23000000&transparentBg=1&download=0&random=0.11540887178853154" alt="Cargando" ></div>';
}

/**
 * Funcion para insercion en la base de datos
 * @param archivo
 * @param tipoInsert
 * @param form
 * @param tablaRecargar
 * @param dondeRecargar
 */
function ajaxInsert(archivo, tipoInsert, form, tablaRecargar, dondeRecargar) {
    //poner el resultado del ajax
    $('#' + form).after('<div id="resul" style="text-transform:uppercase; margin:5px" ></div>');
    //donde se muestran los resultados
    var resultado = $('#resul');

    //aplicar css al resultado
    resultado.css({
        'padding': '10px',
        'color': '#501919',
        'background': '#ffffff',
        'border-radius': '4px',
        'margin': '0 auto',
        'text-align': 'center'
    });

    //los dato del form
    var datosEnviados = $('#' + form).serializeArray();
    //el archivo que se encarga
    var archivoF = archivo + '.php?tipo=' + tipoInsert;
    console.log(archivoF);

    $.ajax({
        beforeSend: function () {
            resultado.html('<center>Enviando</center>');
        },

        url: archivoF,
        type: 'POST',
        data: datosEnviados

    }).done(function (response) {
        console.log(response);
        resultado.html(response);


        setTimeout(function () {
            resultado.remove();
            if (tablaRecargar != '' || dondeRecargar != '') {
                cargarPHP(tablaRecargar, dondeRecargar)
            }
        }, 2500);

    }).fail(function (response) {
        resultado.text('Error de Ajax');
    });
}
/**
 * Get Balance
 * @param archivo
 * @param tipoInsert
 * @param form
 */
function getBalance(archivo, tipoInsert, form) {
    var resultado = $('#data');

    //aplicar css al resultado
    resultado.css({
        'padding': '20px',
        'color': '#501919',
        'background': '#ffffff',
        'border-radius': '4px',
        'margin': '0 auto',
        'text-align': 'center'
    });

    //los dato del form
    var datosEnviados = $('#' + form).serializeArray();
    //el archivo que se encarga
    var archivoF = archivo + '.php?tipo=' + tipoInsert;
    console.log(archivoF);

    $.ajax({
        beforeSend: function () {
            resultado.html('<center>Enviando</center>');
        },
        url: archivoF,
        type: 'POST',
        data: datosEnviados

    }).done(function (response) {
        console.log(response);
        resultado.html(response);

    }).fail(function (response) {
        resultado.text('Error de Ajax');
    });
}

/**
 * Subir imagen mediante ajax
 * @param form
 * @param urlArchivoSubida
 */
function subirAjax(form, urlArchivoSubida) {
    var a = new FormData($("#" + form)[0]);
    $.ajax({
        url: urlArchivoSubida,
        type: "POST",
        data: a,
        cache: !1,
        contentType: !1,
        processData: !1,
        success: function (data) {
            console.log(data);
            $('#actual').remove();
            setTimeout(function () {
                $("#" + form).after('<div id="data-up">' + data + '</div>')
            }, 800)
        },
        error: function () {
            alert('Error ajax');
        }
    })
}

/**
 * Export cuotas
 * @param archivo
 * @param tipoInsert
 * @param form
 */
function exportCuotas(archivo, tipoInsert, form) {
    //poner el resultado del ajax
    $('#' + form).after('<div id="resul" style="text-transform:uppercase; margin:5px" ></div>');
    //donde se muestran los resultados
    var resultado = $('#resul');

    //aplicar css al resultado
    resultado.css({
        'padding': '20px',
        'color': '#501919',
        'background': '#ffffff',
        'border-radius': '4px',
        'margin': '0 auto',
        'text-align': 'center'
    });

    //los dato del form
    var datosEnviados = $('#' + form).serializeArray();
    //el archivo que se encarga
    var archivoF = archivo + '.php?tipo=' + tipoInsert;
    console.log(archivoF);

    $.ajax({
        url: archivoF,
        type: 'POST',
        data: datosEnviados,
        beforeSend: function () {
            resultado.html('<center>Enviando</center>');
        }
    }).done(function (response) {
        console.log(response);
        resultado.html('');
    }).fail(function (response) {
        resultado.text('Error de Ajax');
    });
}

/**
 * funcion cargar las tablas
 * @param carga
 * @param archivo
 * @param listo
 */
function cargar(carga, archivo, listo) {
    $(carga).load(archivo, function () {
    });
}

/**
 * Ver una clase
 * @param archivo
 * @param tipoInsert
 * @param form
 * @param tablaRecargar
 * @param dondeRecargar
 */
function verClases(archivo, tipoInsert, form, tablaRecargar, dondeRecargar) {
    //poner el resultado del ajax
    $('#' + form).after('<div id="resul" style="text-transform:uppercase; margin:5px" ></div>');
    //donde se muestran los resultados
    var resultado = $('#resul');

    //aplicar css al resultado
    resultado.css({
        'padding': '20px',
        'color': '#501919',
        'background': '#ffffff',
        'border-radius': '4px',
        'margin': '0 auto',
        'text-align': 'center'
    });

    //los dato del form
    var datosEnviados = $('#' + form).serializeArray();
    //el archivo que se encarga
    var archivoF = archivo + '.php?tipo=' + tipoInsert;
    console.log(archivoF);

    $.ajax({
        beforeSend: function () {
            resultado.html('<center>Enviando</center>');
        },

        url: archivoF,
        type: 'POST',
        data: datosEnviados

    }).done(function (response) {
        console.log(response);
        //ver las clases
        $('#ver_clases').html(response);

        setTimeout(function () {
            resultado.remove();

            if (tablaRecargar != '' || dondeRecargar != '') {
                cargarPHP(tablaRecargar, dondeRecargar)
            }
        }, 800);

    }).fail(function (response) {
        resultado.text('Error de Ajax');
    });
}

/**
 * Editar
 * @param id
 * @param archivo
 */
function edit(id, archivo) {
    $('table').before("<div id='edicion'></div> ");
    //cargar el bloque de edicion
    $('#edicion').load(archivo + '?id=' + id, function () {
    });
}

/**
 * function borrar
 * @param id
 * @param tabla
 * @param tipo
 * @returns {boolean}
 */
function borrar(id, tabla, tipo) {
    $('table').before('<div id="borrar"></div>');
    if (!confirm('Desea borrar')) {
        return false;
    }
    $('#borrar').load('fun/delete.php?id=' + id + '&tabla=' + tabla + '&tipo=' + tipo, function () {
        console.log('Borrado');
        cargarPHP('sec/listar' + tabla + '.php', '#data');
    });
}

/**
 * Funcion cerrar
 * @param iden
 */
function cerrate(iden) {
    $(iden).remove();
}
/**
 * Ocultar
 * @param iden
 */
function ocultar(iden) {
    $(iden).css('display', 'none');
    $("video")[0].pause();
}


/**
 * Llevar el scroll
 */
function scrollWinTop() {
    $('html,body').animate({
        scrollTop: 0
    }, 0);
}

/**
 * Tomar URL completa
 * @returns {{}}
 */
function getVarsUrl() {
    var url = location.search.replace("?", "");
    var arrUrl = url.split("&");
    var urlObj = {};
    for (var i = 0; i < arrUrl.length; i++) {
        var x = arrUrl[i].split("=");
        urlObj[x[0]] = x[1]
    }
    return urlObj;
}

/**
 * centrar un div
 * @param iden
 */
function centrar(iden) {
    $(iden).css({
        position: 'absolute',
        left: ($(window).width() - $(iden).outerWidth()) / 2,
        top: ($(window).height() - $(iden).outerHeight()) / 3
    });

    $(window).resize(function () {
        // aquí le pasamos la clase o id de nuestro div a centrar (en este caso "caja")
        $(iden).css({
            position: 'absolute',
            left: ($(window).width() - $(iden).outerWidth()) / 2,
            top: ($(window).height() - $(iden).outerHeight()) / 3
        });
    });


    //ejecutar la funcion
    $(window).resize();

}

/**
 * Crear una ventana emergente
 * @param archivo
 * @param tipo
 */
function ventanaEmergente(archivo, tipo) {
    //crear el visor
    var visor = '' +
        '<div id="ventanaemergente">' +
        '<button class="btn btn-xs btn-danger" onclick="cerrate(\'#ventanaemergente, #abajo\')">' +
        '<i class="fa fa-close fa-2x"></i></button>' +
        '<div id="cargate2"></div></div>';

    //crear el div de abajo
    var abajo = '<div id="abajo"></div>';

    //poner la ventana y el fondo negro
    $('body').append(visor);
    $('body').prepend(abajo);

    $('#abajo').css({
        'background': 'rgba(0,0,0,.7)',
        'width': '100%',
        'height': '300% !important',
        'top': 0,
        'left': 0,
        'right': 0,
        'bottom': 0,
        'margin': '0 auto',
        'z-index': 500,
        'position': 'fixed'
    });

    $('#ventanaemergente').css({
        'width': '97%',
        'height': 'auto',
        'padding': '10px',
        'position': 'absolute',
        'left': '50%',
        'top': '5%',
        'transform': 'translate(-50%,0)',
        'background-color': 'white',
        'z-index': '800',
        'box-shadow': '0 0 3px black',
        'border-radius': '10px'
    });

    //cargar el contenido
    if (tipo == '' || !tipo) {
        $('#cargate2').load(archivo);
    } else {
        $('#cargate2').load(archivo + '?tipo=' + tipo);
    }
}

/**
 * funcion para carga de php
 * @param archivo
 * @param donde
 */
function cargarPHP(archivo, donde) {
    $(donde).before('<div id="resultado"></div>');

    $.ajax({
        type: "GET",
        url: archivo,
        data: '',
        dataType: "html",
        beforeSend: function () {
            //imagen de carga
            $("#resultado").html(cargando());
        },
        error: function () {
            console.log("error petición ajax");
        },
        success: function (data) {
            $(donde).html(data);
            $("#resultado").remove();

        }
    });
}

/**
 * Cargar cuotas
 * @param id
 * @param plataforma
 * @returns {*}
 */

function cuotas(id, plataforma) {
    //scrollWinTop();
    var cuotas = $('#cuotasD');
    cuotas.css('display', 'block');

    try {
        if (plataforma == true) {
            cuotas.load('sec/detalleCuotas.php?id=' + id + '&plat=1');
        } else {
            cuotas.load('sec/detalleCuotas.php?id=' + id + '&plat=0');
        }
    }
    catch (err) {
        alert(err.message);
    }

    return id;
}

/**
 * Cuotas para la busqueda
 * @param id
 * @param plataforma
 */
function cuotas2(id, plataforma) {
    var file;

    try {
        if (plataforma == true) {
            file = 'sec/detalleCuotas.php?id=' + id + '&plat=1';
        } else {
            file = 'sec/detalleCuotas.php?id=' + id;
        }

        //cargar la ventana
        ventanaEmergente(file, '');
    }
    catch (err) {
        alert(err.message);
    }


}

function cerrar(iden) {
    var cuotas = $(iden);
    cuotas.css('display', 'none');
}


/**
 * Para el manejo de cuotas
 * @type {{pasar: CUOTAS.pasar}}
 */
var CUOTAS = {
    /**
     * Pagar la cuota
     * @param id
     * @param paga
     * @param rec
     * @param plataforma
     */
    pagar: function (id, paga, rec, plataforma) {

        var imprimir = false;
        /*if (confirm('¿Desea imprimir el comprobante?')) {
            imprimir = true;
        }*/

        if (plataforma === void 0) {
            plataforma = 0;
        }

        var cuotas = $('#cuotasD');
        cuotas.css('display', 'block');

        $.ajax({
            beforeSend: function () {
            },
            type: "GET",
            url: 'fun/pagar.php?id=' + id + '&paga=' + paga + '&plataforma=' + plataforma
        }).done(function (response) {
            console.log(response);
            $('#resul').html(response);

            if (response == 'Actualizada') {
                if (imprimir) {
                    CUOTAS.generarRecibo(id)
                }
            }

            setTimeout(function () {
                $("#cuotasD").load("sec/detalleCuotas.php?id=" + rec + '&plat=' + plataforma);
            }, 100);

        }).fail(function () {
            console.log('Error de Ajax');
        });
    },
    /**
     * Pasar la cuota
     * @param id
     * @param pasada
     * @param rec
     */
    pasar: function (id, pasada, rec) {
        var cuotas = $('#cuotasD');
        cuotas.css('display', 'block');

        $.ajax({
            beforeSend: function () {
            },
            type: "GET",
            url: 'fun/pasar.php?id=' + id + '&pasada=' + pasada

        }).done(function (response) {
            console.log(response);
            $('#resul').html(response);

            setTimeout(function () {
                $("#cuotasD").load("sec/detalleCuotas.php?id=" + rec);
            }, 100);

        }).fail(function () {
            console.log('Error de Ajax');
        });
    },

    /**
     * Borrar cuota
     * @param idCuota
     * @param iden
     */
    borrarCuota: function (idCuota, iden) {
        var cuotaDetalle = $("#cuotasD");

        if (confirm('¿Desea borrar la cuotas?')) {
            $.ajax({
                url: 'fun/borrarCuota.php',
                method: 'POST',
                data: {
                    cuota: idCuota
                },
                success: function (res) {
                    console.log(res);
                    if ($.trim(res) == 'Cuota Borrada') {
                        $('#' + iden).remove();
                    }
                },
                error: function (res) {
                    alert(res.responseText);
                }
            })
        } else {
            return false;
        }
    },

    /**
     * Cambiar los valores
     * @param alumno
     */
    cambiarValores: function (alumno) {
        var datos = {
            alumno: $('#alumno_cuota').val(),
            valor: $('#valor_cuota').val()
        };

        $.ajax({
            url: 'fun/changeValor.php',
            method: 'POST',
            data: datos,
            success: function (res) {
                console.log(res);
                if ($.trim(res) == 1) {
                    $("#cuotasD").load("sec/detalleCuotas.php?id=" + alumno);
                    alert('Valores cambiados');
                }
            },
            error: function (res) {
                alert(res.responseText);
            }
        })
    },

    /**
     * Generar un recibo
     * @param cuota
     */
    generarRecibo: function (cuota) {
        $.ajax({
            url: 'fun/actions.php',
            method: 'GET',
            data: {
                cuota:cuota,
                t:'generarRecibo',
                tipo:'generarRecibo',
            },
            beforeSend: function () {

            },
            success: function (res) {

            },
            error: function (res) {

            }
        })
    }
};

/**
 * Video de los cursos
 * @param videoUrl
 */
function video(videoUrl) {
    scrollWinTop();
    $('.video-ventana, #abajo').css('display', 'block');
    $('video').attr('src', videoUrl);
}

/**
 * Sumar fecha
 * @param d
 * @param fecha
 * @returns {string}
 */
sumaFecha = function (d, fecha) {
    var Fecha = new Date();
    var sFecha = fecha || (Fecha.getDate() + "-" + (Fecha.getMonth() + 1) + "-" + Fecha.getFullYear());
    var sep = sFecha.indexOf('-') != -1 ? '-' : '-';
    var aFecha = sFecha.split(sep);
    var fecha = aFecha[2] + '-' + aFecha[1] + '-' + aFecha[0];
    fecha = new Date(fecha);
    fecha.setDate(fecha.getDate() + parseInt(d));
    var anno = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate();
    mes = (mes < 10) ? ("0" + mes) : mes;
    dia = (dia < 10) ? ("0" + dia) : dia;
    var fechaFinal = dia + sep + mes + sep + anno;
    return (fechaFinal);
};

/**
 * Buscar por div
 * @param valor
 * @param claseCSS
 */
function buscar(valor, claseCSS) {
    console.log(valor);
    var cant = $(claseCSS + ":contains(" + valor + ")").length;

    //si ingresa algo
    if (valor != '') {
        console.log('Cant: ' + cant);
        $(".menu-app:contains(" + valor + ")").show();
        $('.menu-app:not(:contains(' + valor + '))').hide()
    }

    //si no pone nada
    if (valor == '' || !valor) {
        console.log('Cant: ' + cant);
        $('.menu-app').show();
    }

}

/**
 * Setear cookie con javacript
 * @param cname
 * @param cvalue
 * @param exdays
 */
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}
/**
 * Cerrar publicidad
 */
function cerrarPubli() {
    $('.cerrar, .publi, .publi-back').remove();
    window.localStorage.setItem('cerrado', 1);
    setCookie('cerrado', 1, 5);
}
