//funciones en ready
$(function () {

    //imagenes
    //$('img').addClass('img-responsive');
    //$('button').addClass('btn');

    //========= slider inicio =============/
    $('.slider-home').slick({
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1200,
        centerMode:false,
        speed: 900,
        slidesToShow: 1,
        adaptiveHeight: true
    });


    //========== Seccion clases =================//
    $('#carga').click(function () {
        var codigo = $('#codigo').val();
        cargarPHP('sec/verClase.php?codigo=' + codigo, '.data');
    });
});

//=========== poner solo numero =================//
function justNumbers(e) {
    var keynum = window.event ? window.event.keyCode : e.which;
    if ((keynum == 8) || (keynum == 46))
        return true;
    return /\d/.test(String.fromCharCode(keynum));
}

(adsbygoogle = window.adsbygoogle || []).push({});

//================ publi 1 ===================//
(function () {
    var po = document.createElement("script");
    po.type = "text/javascript";
    po.async = true;
    po.src = "https://apis.google.com/js/plusone.js?publisherid=109755594004089271519";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(po, s);
})();

google_ad_client = "ca-pub-5336087265917978";
/* frente */
google_ad_slot = "8708725243";
google_ad_width = 907;
google_ad_height = 90;


//=========== google analitycs ==============//
(function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

ga('create', 'UA-47059780-1', 'auto');
ga('send', 'pageview');
